<template>
  <div style="min-height: calc(100vh - 130px)">
    <table-template
      :isshowopt="1"
      :treeProps="{ children: 'list', hasChildren: 'hasChildren' }"
      :table_data="table_data"
      :formatList="['task_type']"
    >
      <template slot="task_type" slot-scope="scope">
        <el-select
          v-model="scope.scope.row.task_type"
          @change="updateType(scope.scope.row)"
          :disabled="scope.scope.row.task_type == 3"
        >
          <el-option
            v-for="(item, index) in task_type_list"
            :key="index"
            :disabled="formatCount(item, scope.scope.row)"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
      </template>
      <template slot-scope="scope">
        <el-link
          :underline="false"
          type="danger"
          @click="showAudit(scope.scope.row)"
          >审核</el-link
        >
      </template>
    </table-template>
    <div style="text-align: right">
      <base-pageination
        :pageinfo="table_data.pageinfo"
        @handlePageClick="handlePageClick"
      ></base-pageination>
    </div>
  </div>
</template>

<script>
import BasePageination from "../components/BasePageination.vue";
import ListConditionForm from "../components/ListConditionForm.vue";
import TableTemplate from "../components/TableTemplate.vue";
import { getYJSList, updateYJSType, pushYJSInfo } from "@/api/audit.js";
export default {
  components: { ListConditionForm, TableTemplate, BasePageination },
  data() {
    return {
      table_data: {
        tableConfig: [
          { key: "apply_name", name: "报装用户" },
          { key: "contact_man", name: "联系人" },
          { key: "telphone", name: "手机号" },
          { key: "task_type", name: "审核状态" },
          { key: "update_time", name: "创建时间" },
        ],
        data: [],
        pageinfo: { pageNum: 1, pageSize: 10, total: 0 },
      },
      task_type_list: [
        { value: 0, label: "未审核" },
        { value: 1, label: "审核受理" },
        { value: 2, label: "审核中" },
        { value: 3, label: "已办结" },
      ],
      isShowAudit: false,
      replyObj: null,
      reply: {
        content: "",
        username: localStorage.getItem("username"),
        pid: "",
      },
    };
  },
  mounted() {
    this.handlePageClick(1);
  },
  methods: {
    fetchData() {
      getYJSList({
        pageSize: this.table_data.pageinfo.pageSize,
        pageNum: this.table_data.pageinfo.currentpage,
      }).then((res) => {
        const { code, results, page } = res;
        if (code === 10000) {
          this.table_data.data = results.reverse();
          this.table_data.pageinfo = page;
        }
      });
    },
    handlePageClick(page) {
      this.table_data.pageinfo.currentpage = page;
      this.fetchData();
    },
    showAudit(item) {
      this.replyObj = item;
      this.isShowAudit = true;
    },
    async updateType(obj) {
      this.$confirm("确认提交审核信息？", "操作提示").then(async () => {
        pushYJSInfo({
          id: obj.id,
        });
        return;
        const { code, result } = await updateYJSType({
          id: obj.id,
          task_type: obj.task_type,
        });
        if (code === 10000 && result) {
          this.$message.success("提交成功");
          pushYJSInfo({
            id: obj.id,
          });
        }
      });
    },
    formatCount(obj, item) {
      let bool = false;
      if (!(obj.value == item.task_type + 1)) bool = true;
      return bool;
    },
  },
};
</script>

<style></style>
